import React, { Component } from 'react';
import { flowRight } from 'lodash';
import { ConnectedProps } from 'react-redux';
import { WithTranslation, withTranslation } from '@wix/yoshi-flow-editor';
import {
  createPageUrl,
  ENTITY_TYPE_POSTS,
  getAppConfig,
  isLayoutProGallery,
  resolveId,
  resolveLegacyId,
  SECTION_CATEGORY,
  LayoutType,
  feedWidgetStyleParams,
  getCategoryUrl,
} from '@wix/communities-blog-client-common';
import AnimatedLoader from '../../../common/components/animated-loader';
import AppLoaded from '../../../common/components/app-loaded';
import withComponents, {
  WithComponents,
} from '../../../common/components/components-provider/with-components';
import { EmptyLayout } from '../../../common/components/layout';
import Page from '../../../common/components/page';
import { connect } from '../../../common/components/runtime-context';
import Pagination from '../../../common/containers/pagination';
import withAuth, { WithAuth } from '../../../common/hoc/with-auth';
import withLayoutProps, {
  WithLayoutProps,
} from '../../../common/hoc/with-layout-props';
import withPermissions, {
  WithPermissions,
} from '../../../common/hoc/with-permissions';
import { getAppSettingsValue } from '../../../common/selectors/app-settings-base-selectors';
import {
  getCanSeeMoreButton,
  getIsCategoryHeaderEnabled,
} from '../../../common/selectors/app-settings-selectors';
import { isCreatePostButtonVisible } from '../../../common/selectors/blog-menu-button-selectors';
import {
  isExcludePostContentSupported,
  isPostContentRequired,
} from '../../../common/selectors/layout-selectors';
import {
  getPostsByCategoryIdAndPage,
  getSortedPostsByCategoryId,
} from '../../../common/selectors/post-selectors';
import { handleNavigation } from '../../../common/services/navigation';
import { resolveCategorySlug } from '../../../common/services/slug';
import {
  getIsMobile,
  isSite as getIsSite,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getCategoryBySlug } from '../../../common/store/categories/categories-selectors';
import { getIsCategoryLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { getIsCategoryLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import CategoryHeader from '../../components/category-header';
import PostList from '../../components/post-list';
import { RouteCategoryParams } from '../../constants/routes';
import withPaginationSettings, {
  WithPaginationSettings,
} from '../../hoc/with-pagination-settings';
import NoPosts from '../no-posts';

type OwnProps = {
  params: RouteCategoryParams;
};

type Props = OwnProps &
  WithComponents &
  WithPermissions &
  WithPaginationSettings &
  WithLayoutProps &
  WithAuth &
  WithTranslation &
  ConnectedProps<typeof connector>;

const Layout = EmptyLayout;

export class CategoryPage extends Component<Props> {
  componentWillUnmount() {
    const { category } = this.props;

    this.props.setIsLoaded('category', resolveId(category), false);
  }

  componentDidUpdate({
    isAuthenticated,
    isBlocked,
    pageSize,
    excludeContent,
    params,
  }: Props) {
    const { category } = this.props;
    if (
      this.props.isAuthenticated !== isAuthenticated ||
      this.props.isBlocked !== isBlocked ||
      this.props.pageSize !== pageSize ||
      (excludeContent && !this.props.excludeContent)
    ) {
      this.fetchPosts(resolveId(category));
    }

    if (this.props.params.categorySlug !== params.categorySlug) {
      this.props.paginationClearCollection({ entityType: ENTITY_TYPE_POSTS });
    }
  }

  fetchPosts(
    categoryId: string,
    page = this.props.page,
  ): Promise<any> | undefined {
    if (this.props.isBlocked) {
      return;
    }

    return this.props.fetchCategoryPosts({
      categoryId,
      page,
      excludeContent: this.props.excludeContent,
    });
  }

  loadMore = (page: number) =>
    this.fetchPosts(resolveId(this.props.category), page);
  loadRest = () =>
    this.fetchPosts(resolveId(this.props.category), this.props.page);

  renderPosts() {
    const {
      currentPagePosts,
      allPosts,
      entityCount,
      category,
      isCategoryLoading,
      isCategoryLoaded,
      showCreatePostAction,
      page,
      layoutType,
      showPagination,
      PostListProGallery,
      canSeeMoreButton,
      pageSize,
    } = this.props;

    if (allPosts.length === 0 && isCategoryLoaded && category) {
      // @ts-expect-error
      return <NoPosts category={category} />;
    }

    if (isLayoutProGallery(layoutType as LayoutType)) {
      return (
        <PostListProGallery
          pageStart={page + 1}
          entityCount={entityCount}
          loadMore={showPagination ? undefined : this.loadMore}
          allPosts={showPagination ? currentPagePosts : allPosts}
          currentPagePosts={currentPagePosts}
          isLoading={isCategoryLoading}
          showCreatePostAction={showCreatePostAction}
          canSeeMoreButton={canSeeMoreButton}
          layoutOptions={this.props.layoutOptions}
          section={SECTION_CATEGORY}
        />
      );
    }

    return (
      <PostList
        pageStart={page + 1}
        layoutType={layoutType}
        entityCount={entityCount}
        page={page}
        pageSize={pageSize}
        loadRest={showPagination ? this.loadRest : undefined}
        loadMore={showPagination ? undefined : this.loadMore}
        allPosts={showPagination ? currentPagePosts : allPosts}
        currentPagePosts={currentPagePosts}
        isLoading={isCategoryLoading}
        category={category}
        isLoaded={isCategoryLoaded}
        showCreatePostAction={showCreatePostAction}
      />
    );
  }

  createPageUrl = (page: number) => createPageUrl(page, this.props.categoryUrl);

  renderPagination() {
    const {
      entityCount,
      pageSize,
      page,
      isSite,
      categoryPath,
      showPagination,
    } = this.props;
    return (
      <div
        data-hook="category-pagination-container"
        style={{ display: showPagination ? 'block' : 'none' }}
      >
        <Pagination
          page={page}
          pageSize={pageSize}
          entityCount={entityCount}
          createPageUrl={this.createPageUrl}
          handleNavigation={handleNavigation(categoryPath, isSite)}
          showPagination={showPagination}
        />
      </div>
    );
  }

  render() {
    const {
      category,
      isCategoryLoading,
      isCategoryLoaded,
      allPosts,
      isCategoryHeaderEnabled,
    } = this.props;

    const isLoading = isCategoryLoading && !isCategoryLoaded;

    return (
      <Page noSpacing>
        <Layout>
          {isCategoryHeaderEnabled && <CategoryHeader category={category} />}
          <AnimatedLoader isLoading={isLoading && !allPosts.length}>
            {this.renderPosts()}
          </AnimatedLoader>
          {!isLoading && this.renderPagination()}
          {isCategoryLoaded && <AppLoaded />}
        </Layout>
      </Page>
    );
  }
}

const connector = connect(
  (
    state,
    ownProps: OwnProps & WithPermissions & WithPaginationSettings,
    actions,
  ) => {
    const categorySlug = resolveCategorySlug(ownProps.params);
    const category = getCategoryBySlug(state, categorySlug);
    const categoryId = resolveId(category);
    const mixedCategoryId = resolveLegacyId(category) ?? categoryId;

    const currentPagePosts = mixedCategoryId
      ? getPostsByCategoryIdAndPage(state, mixedCategoryId, ownProps.page)
      : [];
    const allPosts = mixedCategoryId
      ? getSortedPostsByCategoryId(state, mixedCategoryId)
      : [];
    const { categoryPath, useCategoryMenuLabelForMetadataTitle } =
      getAppConfig(state);
    const sectionUrl = getSectionUrl(state);
    const categoryUrl = getCategoryUrl(sectionUrl, categoryPath, categorySlug);

    const showCreatePostAction =
      getIsMobile(state) && isCreatePostButtonVisible(state, ownProps.canSee);

    const {
      feedSliderShowArrows,
      feedSliderArrowsSize,
      feedSliderAutoSlide,
      feedSliderPauseTime,
      feedSliderArrowsPosition,
      feedSliderArrowsColor,
      feedSliderLoop,
    } = feedWidgetStyleParams;

    return {
      categoryUrl,
      categoryPath: `/${categoryPath}/${categorySlug}`,
      category,
      allPosts,
      currentPagePosts,
      categoryId,
      isCategoryLoading: getIsCategoryLoading(state, categoryId),
      isCategoryLoaded: getIsCategoryLoaded(state, categoryId),
      isSite: getIsSite(state),
      showCreatePostAction,
      excludeContent: isExcludePostContentSupported(state),
      useCategoryMenuLabelForMetadataTitle,
      setIsLoaded: actions.setIsLoaded,
      fetchCategoryPosts: (params: {
        page: number;
        categoryId: string;
        excludeContent: boolean;
      }) => {
        return actions.fetchCategoryPostsWithAdapterPromisified({
          page: params.page,
          includeContent: isPostContentRequired(state),
          categoryOptions: { id: params.categoryId },
        });
      },
      paginationClearCollection: actions.paginationClearCollection,
      isCategoryHeaderEnabled: getIsCategoryHeaderEnabled(state),
      canSeeMoreButton: getCanSeeMoreButton(state, ownProps.canSee),
      layoutOptions: {
        showArrows: getAppSettingsValue({
          state,
          key: `style.booleans.${feedSliderShowArrows.key}`,
          fallback: feedSliderShowArrows.defaultValue,
        }),
        arrowsSize: getAppSettingsValue({
          state,
          key: `style.numbers.${feedSliderArrowsSize.key}`,
          fallback: feedSliderArrowsSize.defaultValue,
        }),
        autoSlide: getAppSettingsValue({
          state,
          key: `style.booleans.${feedSliderAutoSlide.key}`,
          fallback: feedSliderAutoSlide.defaultValue,
        }),
        pauseTime: getAppSettingsValue({
          state,
          key: `style.numbers.${feedSliderPauseTime.key}`,
          fallback: feedSliderPauseTime.defaultValue,
        }),
        arrowsPosition: getAppSettingsValue({
          state,
          key: `style.numbers.${feedSliderArrowsPosition.key}`,
          fallback: feedSliderArrowsPosition.defaultValue,
        }),
        arrowsColor: getAppSettingsValue({
          state,
          key: `style.colors.${feedSliderArrowsColor.key}`,
          fallback: feedSliderArrowsColor.defaultValue,
        }),
        loop: getAppSettingsValue({
          state,
          key: `style.booleans.${feedSliderLoop.key}`,
          fallback: feedSliderLoop.defaultValue,
        }),
      },
    };
  },
);

export default flowRight(
  withComponents,
  withPermissions,
  withPaginationSettings(SECTION_CATEGORY),
  withLayoutProps(),
  withAuth,
  withTranslation(),
  connector,
)(CategoryPage);

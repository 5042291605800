import React from 'react';
import {
  ComponentsContext,
  ComponentsContextValue,
} from './components-context';

const Fallback: React.FC = () => null;

const ComponentsProvider: React.FC<
  React.PropsWithChildren<ComponentsContextValue>
> = ({ children, ...rest }) => (
  <ComponentsContext.Provider value={{ ...rest }}>
    {React.Children.only(children)}
  </ComponentsContext.Provider>
);

ComponentsProvider.defaultProps = {
  PostListProGallery: Fallback as any,
  PostListSimple: Fallback as any,
  PostListMasonry: Fallback as any,
  PostListMobile: Fallback as any,
  PostListItem: Fallback as any,
  Post: Fallback as any,
  SideBySideMobile: Fallback as any,
  TextOnImageMobile: Fallback as any,
};

export default ComponentsProvider;
